var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      { staticClass: "menuList" },
      [
        _c("sidebar-menu", {
          attrs: {
            menu: _vm.getActiveMenu,
            SystemMenu: _vm.getActiveMenu,
            collapsed: _vm.collapsed,
            lcdItem: _vm.getActiveMenu,
            theme: _vm.selectedTheme,
            "show-one-child": true
          },
          on: {
            "toggle-collapse": _vm.onToggleCollapse,
            "item-click": _vm.onItemClick
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }