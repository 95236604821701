var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "etc" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { text: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("Privacy Policy")))]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog_privacy,
            callback: function($$v) {
              _vm.dialog_privacy = $$v
            },
            expression: "dialog_privacy"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-info" }),
                _vm._v(_vm._s(_vm.$t("Privacy Policy")) + " ")
              ]),
              _c("div", { staticClass: "termsBox", attrs: { id: "terms" } }, [
                _c("p", [
                  _vm._v(
                    ' SOLUM CO., LTD("We") respect your concerns about privacy. This Privacy Policy applies to the personal information we collect in connection with the services we provide, including in relation to our application and the use of Dashboard (the “Application”). '
                  )
                ]),
                _c("p", [_c("br")]),
                _c("h4", { staticClass: "article1" }, [
                  _vm._v("OVERVIEW OF PRIVACY POLICY")
                ]),
                _vm._v(" ========================================"),
                _c("br"),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " In this Privacy Policy, we describe the types of personal information we process in relation to the Services, how we use the personal information, how long we retain the personal information and the choices available to you and your rights regarding the use of your personal information, including the right to object to some of the processing we carry out. More information about your rights, and how to exercise them, is set out in the Your Rights section below. This Privacy Policy will also tell you how to contact us about our privacy practices. Below is a list of key topics addressed in this Privacy Policy. For more detailed information on how we process your personal information, please click on each heading or continue to read below. "
                  )
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor1", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("1. Collection of Personal Information")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor2", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("2. Use of Personal Information")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor3", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("3. Retention of Personal Information")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor4", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("4. Your Rights")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor5", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("5. Protection of Personal Information")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor6", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("6. Updates to Privacy Policy")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2 underline" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "termsLink",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#anchor7", {
                              container: "#terms"
                            })
                          }
                        }
                      },
                      [_vm._v("7. Contact Us")]
                    )
                  ],
                  1
                ),
                _c("br"),
                _c("br"),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor1" } },
                  [_vm._v(" 1. Collection of Personal Information ")]
                ),
                _c("ul", { staticClass: "list-bullet mt-2" }, [
                  _c("li", [
                    _vm._v(
                      " * Information you provide to us through your relationship with us, including but not limited to information provided by you when using the application, we collect the name and the email address when you create the account for accessing to our application. "
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      " * Information such as IP address of the system which you access to our application and date/time log of your visit to the application. "
                    )
                  ])
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor2" } },
                  [_vm._v("2. Use of Personal Information")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " In this section we provide the legal ground on which we rely to process your personal information and under each legal ground, we explain the purposes for which we collect and use your personal information. "
                  )
                ]),
                _c("br"),
                _c("h6", { staticClass: "article2 underline" }, [
                  _vm._v(
                    " Provision of Services based on your contractual relationship with us "
                  )
                ]),
                _c("br"),
                _c("ul", { staticClass: "list-bullet mt-2" }, [
                  _c("li", [
                    _vm._v("* Provide you with the Services on Dashboard")
                  ]),
                  _c("li", [
                    _vm._v(
                      " * Identify and authenticate you so you may use certain services "
                    )
                  ])
                ]),
                _c("br"),
                _c("h6", { staticClass: "article2 underline" }, [
                  _vm._v(
                    " Use of information based on legitimate interests of us "
                  )
                ]),
                _c("br"),
                _c("ul", { staticClass: "list-bullet mt-2" }, [
                  _c("li", [
                    _vm._v(
                      " * Generate the report for informing the abnormal situation of our system you manage and send it to you. "
                    )
                  ])
                ]),
                _c("br"),
                _c("h6", { staticClass: "article2 underline" }, [
                  _vm._v(" Use of information based on your consent ")
                ]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " This data will not be forwarded to third parties without your express consent. "
                  )
                ]),
                _c("br"),
                _c("h6", { staticClass: "article2 underline" }, [
                  _vm._v("Change of purpose")
                ]),
                _c("br"),
                _c("p", [
                  _vm._v(
                    " We will use your personal information for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so. "
                  )
                ]),
                _c("br"),
                _c("h6", { staticClass: "article2 underline" }, [
                  _vm._v(" For purposes which are required by law: ")
                ]),
                _c("br"),
                _c("ul", { staticClass: "list-bullet mt-2" }, [
                  _c("li", [
                    _vm._v(
                      "* Comply with and enforce applicable legal requirements; or"
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "* Respond to requests from government or law enforcement"
                    )
                  ])
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor3" } },
                  [_vm._v(" 3. Retention of Personal Information ")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " We will take appropriate measures to ensure that we only process and retain your information for the duration as required: "
                  )
                ]),
                _c("ul", { staticClass: "list-bullet" }, [
                  _c("li", [_vm._v("* to provide you with the Services;")])
                ]),
                _c("p", [
                  _vm._v(
                    " When you no longer need to use our system, you can remove your account information in our system. "
                  )
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor4" } },
                  [_vm._v("4. Your rights")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " Your personal information belongs to you. To the extent permitted under applicable laws, you may ask us to identify the personal information we have collected from you, and request that we delete it or correct any inaccuracies. You can also ask us to restrict or limit the processing of your personal information, as well as, to provide your personal information for a contract or in a structured, machine readable format, and to share (port) this data to another data controller. "
                  )
                ]),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " In addition, to the extent permitted under applicable laws, you can "
                  ),
                  _c("sup", [_vm._v("object to the processing")]),
                  _vm._v(
                    " of your personal data in some circumstances (in particular, where we don’t have to process the data to meet a contractual or other legal requirement). "
                  )
                ]),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(" These "),
                  _c("strong", [_vm._v("rights may be limited,")]),
                  _vm._v(
                    " for example, if fulfilling your request would reveal personal data about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests in keeping. Relevant exemptions are included in both the GDPR and in equivalent national laws. We will inform you of relevant exemptions that we rely upon, if relevant, when responding to requests that you make. To exercise your rights or to make an inquiry, see the "
                  ),
                  _c(
                    "span",
                    { staticClass: "textline" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "innerLink",
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              return _vm.$vuetify.goTo("#anchor7", {
                                container: "#terms"
                              })
                            }
                          }
                        },
                        [_vm._v("Contact Us")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" section below. ")
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor5" } },
                  [_vm._v(" 5. Protection of Personal Information ")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " If our application is located in your internal network, such as the data center, the information is not exposed to any party. If our application is located in the public cloud, such as MS Azure, the information is protected by the security services provided by the cloud service provider. However, please note that although our system uses several services to protect your information, no website, Internet transmission, computer system, or wireless connection is completely secure. "
                  )
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor6" } },
                  [_vm._v(" 6. Updates to this Privacy Policy ")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " This Privacy Policy may be updated to reflect changes in our personal information practices with respect to the Services, or changes in the applicable law. We will display also the dates on which the policy was most recently updated so that you can catch the change of the policy. "
                  )
                ]),
                _c("br"),
                _c(
                  "h5",
                  { staticClass: "article2", attrs: { id: "anchor7" } },
                  [_vm._v("7. Contact Us")]
                ),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    " If you have any queries in relation to this Privacy Policy or to exercise your rights in relation to your personal information, please contact us at eslsupport@solu-m.com or via post A-tower 6 Floor, 357, Guseong-ro, Giheung-gu, Yongin-si, Gyeonggi-do, Republic of Korea. In addition, the Data Protection Officer can be contacted "
                  ),
                  _c("span", { staticClass: "textline" }, [
                    _vm._v("using boyle.seo@solu-m.com")
                  ]),
                  _vm._v(
                    ". You can also contact the competent data protection authority that is the responsible supervisory authority. "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog_privacy = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("p", [_vm._v("ⓒ 2015-2022 SOLUM")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }