<template>
  <div class="swInfo">
    <!-- Software Information 팝업 -->
    <v-dialog :key="dialog_sw" v-model="dialog_sw" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="handleSoftwareInfoClick"
          v-bind="attrs"
          v-on="on"
          text
          class="btn-swInfo"
        >
          {{ $t('Software Information') }}
        </v-btn>
      </template>

      <v-card class="popup add_store_popup">
        <h3 class="page-title-bar">
          <i class="ico ico-info"></i>{{ $t('Software Information') }}
        </h3>
        <h3 class="tit-swInfo">{{ $t('DASHBOARD REVISION') }}</h3>
        <v-text-field
          :value="dashboardRevision"
          class="form-input mt-2"
          style="width: 290px"
          outlined
          dense
          hide-details
          solo
          readonly
        ></v-text-field>
        <label style="float: right;margin-top: -25px;">
          <span class="tit-swInfo">Version : </span> v{{ Dashboard_version }}
        </label>
        <article>
          <table class="tbl-swInfo mt-10">
            <colgroup>
              <col style="width: 12%" />
              <col style="width: 18%" />
              <col style="width: 16%" />
              <col style="width: 18%" />
              <col style="width: 10%" />
              <col style="width: 10%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('TYPE')}}</th>
                <th>{{ $t('CODE')}}</th>
                <th>{{ $t('ADDRESS')}}</th>
                <th>{{ $t('RELEASE VERSION')}}</th>
                <th>{{ $t('REVISION')}}</th>
                <th>{{ $t('STATUS')}}</th>
                <th>{{ $t('LAST CONNECTED')}}</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="softwareInformationList of softwareInformationListByType"
              >
                <tr
                  v-for="(softwareInformation, idx) of softwareInformationList"
                  :key="
                    softwareInformationList.type +
                      softwareInformation.code +
                      softwareInformation.revision
                  "
                >
                  <td
                    v-if="idx === 0"
                    class="type"
                    :rowspan="softwareInformationList.length"
                  >
                    {{ softwareInformation.nodeType }}
                  </td>
                  <td>{{ softwareInformation.code }}</td>
                  <td>
                    {{
                      `${softwareInformation.ipAddress}:${softwareInformation.port}`
                    }}
                  </td>
                  <td>{{ softwareInformation.version }}</td>
                  <td>{{ softwareInformation.revision }}</td>
                  <td>{{ softwareInformation.nodeStatus }}</td>
                  <td>
                    {{
                      getLastConnectionDate(
                        softwareInformation.lastConnectionDate
                      )
                    }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </article>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="dialog_sw = false" class="btn">{{ $t('Close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configs from '@/plugins/configs'
import codes from '@/plugins/codes'

export default {
  data () {
    return {
      dialog_sw: false,
      dashboardRevision: process.env.VUE_APP_RELEASE,
      softwareInformationListByType: {},
      Dashboard_version: ''
    }
  },
  computed: {},
  mounted () {
    this.Dashboard_version = configs.Version
  },
  methods: {
    // EventHandlers
    handleSoftwareInfoClick () {
      this.initSoftwareInformation()
    },
    // API
    getSoftwareInformationList () {
      const config = { params: {} }
      return this.$utils
        .callAxios(
          codes.requests.getSoftwareInformation.method,
          codes.requests.getSoftwareInformation.url,
          config
        )
        .then(res => {
          if (res.data.responseCode !== '200') {
            throw new Error(
              `The response code is other than 200. code: ${res.data.responseCode}`
            )
          }
          return res.data.nodes
        })
        .catch(error => {
          console.debug(`Could not find software information. error: ${error}`)
          return []
        })
    },
    // etc
    sortSoftwareInformationByType (softwareInformationList) {
      const softwareInformationListByType = {}
      for (const softwareInformation of softwareInformationList) {
        if (!softwareInformationListByType[softwareInformation.nodeType]) {
          softwareInformationListByType[softwareInformation.nodeType] = []
        }
        softwareInformationListByType[softwareInformation.nodeType].push(
          softwareInformation
        )
      }
      return softwareInformationListByType
    },
    async initSoftwareInformation () {
      this.softwareInformationListByType = this.sortSoftwareInformationByType(
        await this.getSoftwareInformationList()
      )
      this.dashboardRevision = configs.Release
    },
    getLastConnectionDate (date) {
      const splitedDate = date.split('T')
      const yearMonthDay = splitedDate[0]
      const time = splitedDate[1].substring(0, splitedDate[1].lastIndexOf(':'))
      return `${yearMonthDay} ${time}`
    }
  }
}
</script>

<style></style>
