var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        staticClass: "navigation",
        style:
          _vm.currentRoute === "/Lcd/pickcel/overview"
            ? "width:50px !important"
            : ""
      },
      [_c("Navigator")],
      1
    ),
    _c(
      "div",
      {
        staticClass: "contentsWarp",
        style:
          _vm.currentRoute === "/Lcd/pickcel/overview"
            ? "width:calc(100% - 50px) !important; margin-left: 50px !important"
            : "",
        attrs: { id: "contentWrap" }
      },
      [
        _vm.currentRoute !== "/Lcd/pickcel/overview"
          ? _c(
              "header",
              { staticClass: "header pr-1", attrs: { id: "header" } },
              [
                _c(
                  "div",
                  { ref: "searchPublicBar", staticClass: "search" },
                  [
                    _c(
                      "v-form",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-search",
                            attrs: {
                              disabled: _vm.searchPublicBarDisabled,
                              text: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openSearchStore()
                              }
                            }
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: true,
                                  expression: "true"
                                }
                              ],
                              ref: "searchIconON",
                              attrs: {
                                src: require("@/assets/img/ico-top-search.png"),
                                alt: ""
                              }
                            }),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              ref: "searchIconOFF",
                              attrs: {
                                src: require("@/assets/img/ico-top-search-disabled.png"),
                                alt: ""
                              }
                            })
                          ]
                        ),
                        _c("popupStore", {
                          attrs: {
                            search: _vm.searchStore,
                            dialog_store: _vm.dialog_store
                          },
                          on: { closeMainStoreModal: _vm.closeMainStoreModal }
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.computedStoreItems,
                            filter: _vm.searchStoreFilter,
                            loading: _vm.isLoading,
                            "item-text": "description",
                            "item-value": "stores",
                            "search-input": _vm.searchStoreLording,
                            placeholder: "" + _vm.$t("Select the store"),
                            disabled: _vm.searchPublicBarDisabled,
                            color: "red",
                            "return-object": ""
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.searchStoreLording = $event
                            },
                            "update:search-input": function($event) {
                              _vm.searchStoreLording = $event
                            }
                          },
                          model: {
                            value: _vm.searchStore,
                            callback: function($$v) {
                              _vm.searchStore = $$v
                            },
                            expression: "searchStore"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "p",
                    { key: "selectedStore", staticClass: "h_storeInfo" },
                    [_vm._v(_vm._s(_vm.selectedStoreText))]
                  ),
                  _c("div", { staticClass: "h_lang drop-down" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-dropdown",
                        attrs: { type: "button", name: "language-dropdown" },
                        on: { click: _vm.toggleDropdown }
                      },
                      [_vm._v(" " + _vm._s(_vm.currentLanguage) + " ")]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "drop-box custom-scrollBox",
                        staticStyle: { height: "200px", "overflow-y": "hide" }
                      },
                      _vm._l(_vm.languagelist, function(language, index) {
                        return _c(
                          "li",
                          {
                            key: language.id,
                            staticStyle: { cursor: "pointer", padding: "10px" },
                            on: {
                              click: function($event) {
                                return _vm.setActive(language, index)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(language.languageName) + " ")]
                        )
                      }),
                      0
                    )
                  ]),
                  _c("div", { staticClass: "h_member drop-down" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-dropdown",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleDropdown }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.displayUserId) + " "),
                        _c("i", [_vm._v(_vm._s(_vm.user.accountGroup))])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "drop-box" },
                      [
                        _c("div", { staticClass: "_member" }, [
                          _c("p", { staticClass: "name" }, [
                            _vm._v(_vm._s(_vm.user.firstName))
                          ]),
                          _c("p", { staticClass: "id" }, [
                            _vm._v("(ID : " + _vm._s(_vm.displayUserId) + ")")
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "myAccount" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: { path: _vm.MyAccountLink } } },
                              [_vm._v(_vm._s(_vm.$t("My Account")))]
                            )
                          ],
                          1
                        ),
                        _vm.cloudMode
                          ? _c("div", { staticClass: "dashboard_ver" }, [
                              _c("p", [_vm._v("SoftWare")]),
                              _c("div", { staticClass: "versionInfo" }, [
                                _c("p", [
                                  _vm._v(
                                    "Release Version : " +
                                      _vm._s(_vm.user.releaseVersion)
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Dashboard : " +
                                      _vm._s(_vm.DashBoard_Release)
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Core : " + _vm._s(_vm.user.coreRevision)
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Portal : " +
                                      _vm._s(_vm.user.portalRevision)
                                  )
                                ])
                              ])
                            ])
                          : _c("main-software-information"),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-logout",
                                attrs: { text: "", width: "100%" },
                                on: { click: _vm.onLogout }
                              },
                              [_vm._v(_vm._s(_vm.$t("Log out")))]
                            )
                          ],
                          1
                        ),
                        _c("main-privacy-policy")
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "contents",
            style:
              _vm.currentRoute === "/Lcd/pickcel/overview"
                ? "padding: 00px !important"
                : ""
          },
          [_c("router-view")],
          1
        )
      ]
    ),
    _c(
      "div",
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "300" },
            model: {
              value: _vm.messageDialog,
              callback: function($$v) {
                _vm.messageDialog = $$v
              },
              expression: "messageDialog"
            }
          },
          [
            _c(
              "v-card",
              {
                staticClass: "popup add_store_popup",
                attrs: { align: "center", justify: "center" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.messageText) }
                      })
                    ])
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn",
                        attrs: { text: "", icon: "" },
                        on: {
                          click: function($event) {
                            _vm.messageDialog = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("OK")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "300", persistent: "" },
            model: {
              value: _vm.loadingDialog,
              callback: function($$v) {
                _vm.loadingDialog = $$v
              },
              expression: "loadingDialog"
            }
          },
          [
            _c(
              "v-card",
              {
                staticClass: "popup add_store_popup",
                attrs: { align: "center", justify: "center" }
              },
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.loadingText) }
                      })
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-progress-linear", {
                          attrs: { value: "15", indeterminate: "" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        class: {
          loadingLayer: _vm.loadingApi,
          loadingLayerOff: !_vm.loadingApi
        }
      },
      [
        _c(
          "div",
          { staticClass: "loadingBar" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }