import commons from '@/plugins/commons'

const lcdItem = {
  components: {},
  props: {},
  data () {
    return {
      lcdItem: [
        {
          href: '/Lcd',
          title: 'Overview',
          code: '21000',
          icon: {
            element: 'span',
            class: 'menu-overview'
          }
        },
        {
          href: '/Lcd/Lcd',
          title: 'LCD',
          code: '22000',
          icon: {
            element: 'span',
            class: 'menu-lcd'
          },
          child: [
            {
              href: '/Lcd/Lcd/LcdList',
              title: 'LCD List',
              code: '22100'
            }
          ]
        },
        {
          href: '/Lcd/Contents',
          title: 'Contents',
          code: '23000',
          icon: {
            element: 'span',
            class: 'menu-contents'
          },
          child: [
            {
              href: '/Lcd/Contents/MediaList',
              title: 'Media List',
              code: '23100'
            },
            {
              href: '/Lcd/Contents/ProductList',
              title: 'Product List',
              code: '23200'
            }
          ]
        },
        {
          href: '/Lcd/Playlist',
          title: 'Playlist Group',
          code: '24000',
          icon: {
            element: 'span',
            class: 'menu-playlist-group'
          },
          child: [
            {
              href: '/Lcd/Playlist/Playlist',
              title: 'Playlist Group List',
              code: '24100'
            }
          ]
        },
        {
          href: '/Lcd/Template/',
          title: 'Template',
          code: '25000',
          icon: {
            element: 'span',
            class: 'menu-template'
          },
          child: [
            {
              href: '/Lcd/Template/TemplateList',
              title: 'Template List',
              code: '25100'
            },
            {
              href: '/Lcd/Template/TemplateMappingList',
              title: 'Template Mapping',
              code: '25200'
            }
          ]
        }
        // {
        //   href: '/Lcd/Setting',
        //   title: 'Setting',
        //   code: '16000',
        //   icon: {
        //     element: 'span',
        //     class: 'menu-system'
        //   }
        // }
      ]
    }
  },
  computed: {
    getLcdItem () {
      // Null check
      if (commons.isNull(this.user)) return []
      if (commons.isNull(this.$store.state.auth.user.accountInfo)) return []
      const accessMenu = this.$store.state.auth.user.accountInfo.accessMenu
      if (!commons.isValidStr(accessMenu)) return []

      const essentialMenu = ['1000', '8400', '21000']
      // Delete non-accessable items
      const menuList = JSON.parse(JSON.stringify(this.lcdItem))
      let activeMenu = menuList.map(parent => {
        if (parent.child !== undefined) {
          // 하위요소 존재하면 사용자의 Access Menu와 대조하여 접근불가한 메뉴 삭제.
          parent.child = parent.child.filter(childItem => {
            // 하위 요소가 accessMenu에 포함되고 요소가 1차 테스트 대상인 경우만 남기고 나머지는 삭제.

            if (
              this.productionType === 'cloud' &&
              this.cloudPages[childItem.title]
            ) {
              childItem.href = childItem.href + 'Cloud'
            }

            const accessable =
              (accessMenu.includes(childItem.code)) ||
              essentialMenu.includes(childItem.code)
            return accessable
          })
        }
        return parent
      })
      activeMenu = activeMenu.filter(
        parent =>
          (!commons.isNull(parent.child) && parent.child.length > 0) ||
          essentialMenu.includes(parent.code)
      )
      // Change default url of a parent link
      // in case of the existing default url is disabled by user's configuration.
      this.setDefaultParentUrl(activeMenu)
      this.setDisabledLinksParentUrl(activeMenu)
      return activeMenu
    }
  },
  watch: {}
}

export default lcdItem
